
#promo .middle p.fadeInUp {
    margin-left: auto;
    margin-right: auto;
    width: 50%;
}

#nav a {
  text-transform: capitalize;
}

p.no_hover a {
  -webkit-transition: color 0s ease;
  transition: color 0s ease;
}

p.no_hover a:hover {
  color: #999;
  border: 0;
}
